@import "config/common.scss";

.Lead {
  min-height: calc(100vh - 11rem);
  width: 100%;

  color: $text-primary;
}

.LeadTop {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.LeadTopLeft {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LeadTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
  word-break: break-all;
}

.LeadSubtitle {
  font-size: 1rem;
  font-weight: 700;
  color: $text-primary;
}

.LeadList {
  display: flex;
  flex-direction: column;
}

//.Home {
//  margin-bottom: 1rem;
//  //border-bottom: 1px solid $color-primary;
//  padding: 1rem 1rem 0.5rem 1rem;
//  background-color: $color-white;
//  border-radius: 0.5rem;
//
//  width: 100%;
//  box-sizing: border-box;
//
//  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
//}
//
//.HomeTop {
//  display: flex;
//  flex-direction: column-reverse;
//  justify-content: space-between;
//  align-items: flex-start;
//
//  @include for-tablet-portrait-up {
//    flex-direction: row;
//    justify-content: space-between;
//    align-items: center;
//  }
//}
//
//.HomeTopLeft {
//  display: flex;
//  flex-direction: row;
//}
//
//.HomeTopImage {
//  margin-right: 1rem;
//  height: 4rem;
//  width: 4rem;
//  border-radius: 0.5rem;
//  background-size: cover;
//  background-position: center;
//
//  display: none;
//
//  @include for-tablet-portrait-up {
//    display: block;
//  }
//}
//
//.HomeContent {
//  display: flex;
//  flex-direction: column;
//}
//
//.HomeTopRight {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  justify-content: space-between;
//  width: 100%;
//  margin-bottom: 1rem;
//
//  @include for-tablet-portrait-up {
//    flex-direction: column;
//    align-items: flex-end;
//    justify-content: center;
//    width: auto;
//    margin-bottom: 0rem;
//  }
//}
//
//.HomeTopRightChips {
//  margin-bottom: 0;
//  margin-right: 1rem;
//  display: flex;
//  flex-direction: row;
//  justify-content: flex-end;
//
//  @include for-tablet-portrait-up {
//    margin-bottom: 0.5rem;
//    margin-right: 0rem;
//  }
//}
//
//.HomeTopRightChip {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  font-size: 0.75rem;
//  padding: 0.5rem;
//  border-radius: 0.5rem;
//  color: $color-white;
//  background-color: $color-primary;
//}
//
//.HomeTopRightChip--icon {
//  margin-left: 0.25rem;
//}
//
//.HomeTopRightChip--warn {
//  color: $color-white;
//  background-color: $color-red;
//}
//
//.HomeTopRightButtons {
//  display: flex;
//  flex-direction: row;
//}
//
//.HomeTitle {
//  font-size: 1.5rem;
//}
//
//.HomeHR {
//  margin-bottom: 0.5rem;
//}
//
//.HomeSubtitle {
//  color: $text-secondary;
//}
//
//.LeadList {
//  margin-bottom: 2rem;
//  display: flex;
//  flex-direction: column;
//  align-items: flex-start;
//}
//
//.LeadCard {
//  width: 100%;
//  min-height: 4rem;
//  max-width: 100%;
//
//  //margin-bottom: 0.5rem;
//  padding: 0.5rem 0rem 0rem 0rem;
//
//  display: flex;
//  flex-direction: row;
//  justify-content: space-between;
//  align-items: center;
//
//  border-radius: 0.5rem;
//  background-color: $color-white;
//  // box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
//
//  text-align: left;
//
//  box-sizing: border-box;
//
//  //transition: box-shadow 0.2s;
//  &:hover {
//    // box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);
//    box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
//  }
//
//}
//
//.LeadCardLeft {
//  display: flex;
//  flex-direction: column;
//  justify-content: space-between;
//}
//
//.LeadCardInfo--name {
//  margin-bottom: 0.5rem;
//  font-size: 1rem;
//}
//
//.LeadCardInfo--email {
//  font-size: 0.75rem;
//  color: $text-secondary;
//}
//
//.LeadCardInfo--phone {
//  font-size: 1rem;
//  color: $text-secondary;
//}
//
//.LeadCardInfo--notes {
//  width: 100%;
//  height: 5rem;
//}
//
//.LeadCardRight {
//  display: flex;
//  flex-direction: row;
//  align-items: flex-end;
//}
//
//.LeadButton {
//  width: 8rem;
//  font-size: 0.75rem;
//  margin-left: 1rem;
//}
//
//.LeadsExportOptions {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  color: $color-primary;
//  cursor: pointer;
//}
//
//.LeadsExportOption {
//  display: flex;
//  align-items: center;
//}
//
//.LeadExportOptionIcon {
//  margin-left: 1rem;
//}
//
//.LeadsNoLeadsMessage {
//  margin-top: 1rem;
//  margin-bottom: 0.5rem;
//  width: 100%;
//  display: flex;
//  justify-content: flex-start;
//  align-items: center;
//  text-align: left;
//}
//
//.LeadsNoLeadsLink {
//  font-weight: 700;
//  color: $color-primary;
//}
