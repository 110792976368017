@import "config/common.scss";

.App {
  position: relative;
  font-family: 'Roboto', sans-serif;
  max-height: 100vh;
  overflow: hidden;
}

.AppContent {
  display: flex;
  flex-direction: column;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }
}

.AppScroll {
  overflow-y: scroll;
  height: calc(100vh - 7rem);
  width: calc(100vw - 2rem);
  background-color: rgba(234, 244, 255, 0.81);

  padding: 1rem 1rem 1rem 1rem;

  @include for-tablet-landscape-up {
    height: calc(100vh - 3rem);
    padding: 1rem 2rem 2rem 2rem;
    width: calc(100vw - 4rem);
  }
}

.AppBGBar {
  position: absolute;
  top: 50%;
  left: -25%;
  height: 10rem;
  width: 150vw;
  background-color: rgba(12, 116, 255, 0.25);
  transform: rotate(12deg);
  z-index: -1;
}
