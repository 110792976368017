@import "config/common.scss";

.SidebarContainer {
  width: calc(100vw - 2rem);
  height: 3rem;
  padding: 1rem 1rem 1rem 1rem;
  background-color: $color-white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1;

  transform: translateX(-100vw);

  transition: transform 0.2s, width 0.2s;

  @include for-tablet-landscape-up {
    width: 15rem;
    padding: 1rem 2rem 1rem 2rem;
    height: calc(100vh - 2rem);
    transform: translateX(0);
  }
}

.SideBarContainer--open {
  height: calc(100vh - 2rem);
  transform: translateX(0);
}

.SidebarHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: calc(100vw -  2rem);

  font-weight: 700;
  font-size: 2rem;
  color: $text-primary;

  transform: translateX(100vw);

  transition: transform 0.2s;

  @include for-tablet-landscape-up {
    width: auto;
    transform: translateX(0);
  }
}

.SidebarHead--open {
  width: calc(100vw -  2rem);
  transform: translateX(0);
}

.SidebarHeadButton {
  display:block;
  @include for-tablet-landscape-up {
    display: none;
  }
}

.SidebarLogoHr {
  display:none;
  @include for-tablet-landscape-up {
    display: block;
  }
}

.SidebarLogoImg {
  height: 2.5rem;
  width: auto;
  margin-right: 0.5rem;
}

.SidebarCTA {

}

.SidebarCTAButton {
  margin-top: 2rem;
  text-align: center;
  background-color: $color-primary;
  border-radius: 0.5rem;
  padding: 1rem;
  color: $color-white;
}

.SidebarLinks {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.SidebarLink {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 1.25rem;

  svg {
    color: $color-primary;
  }

  span {
    margin-left: 1rem;
  }

  transition: font-weight 0.2s;

  &:hover {
    font-weight: 700;
  }
}

.SidebarLink--active {
  font-weight: 700;
}

.SidebarBottom {

}

.SidebarContact {
  margin-bottom: 1rem;
  text-align: left;
  color: $text-primary;
  font-size: 0.75rem;
}

.SidebarMadeWithMsg {
  text-align: left;
  color: $text-secondary;
  font-size: 0.5rem;
}
