@import "config/common.scss";

.AddHomeContainer {
  color: $text-primary;
  background-color: $color-white;
  padding: 1rem;
  border-radius: 1rem;
}

.AddHome {

}

.AddHomeTop {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.AddHomeLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AddHomeFormTitle {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
}

.AddHomeForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.AddHomeFormRow {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.AddHomeFormColumn {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.AddHomeFormItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.AddHomeFormMonacoItem {
  margin-bottom: 1rem;
  width: 100%;
}

.AddHomeFormInputTitle {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.AddHomeFormInput {
  width: 100%;
  height: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.AddHomeFormImageInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.AddHomeFormImageInputButton {
  width: 10rem;
  margin-bottom: 0.5rem;
}

.AddHomeFormImage {
  margin-bottom: 1rem;
  width: 50%;
  height: auto;
  border-radius: 1rem;
}

.AddHomeFormTextArea {
  margin-right: 1rem;
  width: 100%;
}

.AddHomeFormInputFeedback {
  margin-bottom: 1rem;
  color: $color-red;
}

.AddHomeFormButton {

}

.AddHomeFormMonacoDescription {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.AddHomeFormMonacoDescriptionItem {
  margin-bottom: 0.5rem;
  color: $text-secondary;
  font-size: 0.75rem;
}

.AddHomeFormMonacoDescription--keyword {
  color: $color-primary;
}
