@import "config/common.scss";

@keyframes animate-up {
  from {transform: translateY(10%); filter: opacity(0);}
  to {transform: translateY(0%); filter: opacity(1);}
}

.Signup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;

  height: 100vh;
  width: 100vw;

  overflow-y: scroll;
}

.SignupWrapper {
  position: relative;
  margin: auto;
}

.SignupInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SignupLogo {
  margin: auto;
  margin-bottom: 2rem;
  height: 4rem;
  width: auto;

  display: none;

  animation-name: animate-up;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;

  @include for-tablet-portrait-up {
  display: block;
  }
}

.SignupFormContainer {
  position: relative;
  // margin: auto;
  max-width: calc(100vw - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: $color-white;
  border-radius: 2rem;
  box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);

  animation-name: animate-up;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
}

.SignupFormTitle {
  color: $text-primary;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.SignupForm {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.SignupFormLink {
  color: $color-primary;
}

.SignupFormAgreement {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25rem;
  margin-bottom: 1rem;
  max-width: 100%;
  padding-left: 0.5rem;
}

.SignupFormInputTitle {
  color: $text-primary;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;

  @include for-tablet-portrait-up {
  font-size: 1rem;
  }
}

.SignupFormInputTitle--Agreement {
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

.SignupFormInput {
  margin-bottom: 1rem;
  width: 25rem;
  box-sizing: border-box;
  max-width: 100%;
  padding-left: 0.5rem;
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
  font-size: 1rem;
  }
}

.SignupFormInput--Agreement {
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  background-color: $color-secondary;
}

.SignupFormInputFeedback {
  margin-bottom: 2rem;
  width: 100%;
  height: 1rem;
}

.SignupFormButton {
  margin: auto;
}

.SignupFormLoginInstead {
  width: 25rem;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.75rem;

  color: $text-primary;
}

