$font-size-primary: 2rem;
$font-size-secondary: 2rem;

$color-primary: #0C74FF;
$color-secondary: rgb(240, 249, 255);
$color-white: #FFFFFF;
$color-green: #5BC236;
$color-red: #c2170d;

$modal-bg: rgba(19, 37, 55, 0.71);

$text-primary: #0E0E0E;
$text-secondary: #424242;

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1300px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
}
