@import "config/common.scss";

@keyframes animate-up {
  from {transform: translateY(0%) skewY(0deg); filter: opacity(0);}
  to {transform: translateY(0%) skewY(0deg); filter: opacity(1);}
}

@keyframes animate-up-img {
  from {transform: translateY(0%) skewY(0deg); filter: opacity(0);}
  to {transform: translateY(0%) skewY(0deg); filter: opacity(0.25);}
}

@keyframes animate-up-bg {
  from {transform: translateY(0%) skewY(-0deg); filter: opacity(0.8);}
  to {transform: translateY(0%) skewY(-0deg); filter: opacity(1);}
}

.AuthBg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  height: 100%;
  width: 100vw;

  background: linear-gradient(to right, #063A80, #0C74FF 50%, #063A80);
  transform: skewY(-10deg);
  transform-origin: 0;

  animation-name: animate-up-bg;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;

  overflow: hidden;
}

.AuthBgImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 150vh;
  width: 100vw;
  transform: skewY(10deg);
  transform-origin: 50vw;

  background-image: url("../../assets/housesclouds.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;

  animation-name: animate-up-img;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;

  //@include for-tablet-portrait-up {
  //  width: 100%;
  //  height: auto;
  //}
}
