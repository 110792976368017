@import "config/common.scss";


@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animate-up-img {
  from {filter: opacity(0);}
  to {filter: opacity(0.1);}
}

.Landing {
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;

  --landing-gutter: 1200px;
}

.LandingSection {
  position: relative;
  color: $text-primary;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.LandingSection--bgblue {
  background-color: rgba(240, 249, 255, 0.51);
  //background: linear-gradient(to right, rgba(240, 249, 255, 0.51), rgba(240, 249, 255, 0.11));
}

.LandingGutter {
  margin: 2rem;
}

// Top Section

.LandingHero {
  position: relative;
  background: linear-gradient(to right, #063A80, #0C74FF 50%, #063A80);
  height: 36rem;
  min-height: 78vh;
  width: 100%;
  color: $color-white;
  overflow: hidden;
}

.LandingHeroGutter {
  position:relative;
  max-width: var(--landing-gutter);
  height: 100%;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.LandingGutter {
  max-width: var(--landing-gutter);
}

.LandingGutterPadding {
  padding: 2rem 2rem 2rem 2rem;

  @include for-tablet-landscape-up {
    padding: 2rem 2rem 2rem 2rem;
  }
}

.LandingHeroBgImg {
  position: absolute;
  top: 0;
  left: 0;
  filter: opacity(0.5) brightness(0.5) contrast(0.5);

  width: 100%;
  height: 100%;

  background-image: url("../../assets/housesclouds.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;

  @include for-tablet-landscape-up {
    filter: opacity(0.8) brightness(0.9);
  }
}

.LandingTextHighlight {
  height: 14rem;
  width: auto;
  display: none;

  @include for-tablet-landscape-up {
    display: block;
  }
}

.LandingHeader {
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: calc(100% - 4rem);
  z-index: 2;
  color: $color-white;
}

.LandingHeaderInner {
  width: 100%;
  max-width: var(--landing-gutter);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.LandingHeaderInnerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.LandingHeaderLogo {
  font-size: 2rem;
  font-weight: 700;
  height: 2.5rem;
  width: auto;
  user-select: none;
  fill: $color-white;
  transform: translateY(-5px);
}

.LandingHeaderLink {
  margin-left: 2rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: none;

  @include for-tablet-landscape-up {
    display: block;
  }
}

.LandingHeaderLogin {
  font-size: 1.25rem;
}

.LandingHeroContentWrapper {
  position:relative;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.LandingHeroContentInner {
  position:relative;

  margin-top: 0rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  color: $color-white;

  z-index: 1;

  animation-name: fadein;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;

  @include for-tablet-landscape-up {
    color: $text-primary;

    justify-content: flex-start;

    box-shadow: 0 0px 0px #0C74FF, 0 10px 80px rgba(12, 116, 255, 0.60);

    background: $color-white;
    padding: 2rem;
    border-radius: 0.5rem;
    filter: opacity(1);

    width: 50%;
  }
}

.LandingHeroContentBg {
  position: absolute;
  height: 100%;
  width: calc(50% + 2rem);
  top: 0.5rem;
  left: -0.5rem;
  background-color: $color-primary;
  filter: opacity(0.5);
  border-radius: 0.5rem;
  z-index: 1;

  display: none;

  animation-name: fadein;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;

  @include for-tablet-landscape-up {
    display: none;
  }
}

.LandingHeroTitle {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: left;
  filter: opacity(1);

  animation-name: fadein;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0.8s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;

  flex-direction: column;

  z-index: 2;

  @include for-tablet-portrait-up {
    font-size: 2.5rem;
  }
}

.LandingHeroSubtitle {
  width: 100%;
  margin-top: 1rem;
  text-align: left;
  font-weight: 500;
  filter: opacity(1);

  animation-name: fadein;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 1.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;

  z-index: 2;

  @include for-tablet-portrait-up {
    font-size: 1.15rem;
    line-height: 1.7rem;
  }
}

.LandingHeroSignUp {
  margin: auto;
  margin-top: 2rem;
  width: 10rem;
}

.LandingSectionQuote {
  margin: 4rem auto 4rem auto;
  text-align: center;
  font-size: 1.5rem;
  box-sizing: border-box;
}

.LandingSectionQuoteHr {
  margin: auto;
  margin-top: 0.5rem;
  width: 20rem;
  height: 0.2rem;
  color: $color-primary;
  border: none;
  background-color: $color-primary;
  border-radius: 1rem;
}

.LandingSectionQuoteAuthor {
  margin: auto;
  margin-top: 2rem;
  font-size: 1.25rem;
  color: $text-secondary;
}

.LandingSectionInfo {
  margin: auto;
  padding: 2rem;
}

.LandingSectionInfoTitle {
  font-size: 2.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

.LandingSectionInfoTitle--center {
  text-align: center;
  margin: auto;
}

.LandingSectionInfoHr {
  margin-top: 0.2rem;
  margin-left: 0rem;
  width: 10rem;
  height: 0.2rem;
  color: $color-primary;
  border: none;
  background-color: $color-primary;
  border-radius: 1rem;
}

.LandingSectionInfoHr--center {
  margin: auto;
}

.LandingSectionInfoContent {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: $text-primary;
  height: auto;
  transition: height 0.2s, filter 0.2s;
}

.LandingSectionInfoContent--row {
  display: flex;
  flex-direction: column;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }
}

.LandingSectionInfoContent--rowCenter {
  align-items: center;
}

.LandingSectionInfoContent--rowSpaceBetween {
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
}

.LandingSectionInfoContent--column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LandingSectionWhatWeDoInfoCard {
  margin-right: 2rem;
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  color: $text-primary;
  background-color: $color-secondary;
  border-radius: 0.5rem;
  box-shadow: 0 0px 0px #0C74FF, 0 5px 20px rgba(12, 116, 255, 0.20);

  @include for-tablet-landscape-up {
    width: auto;
    height: max-content;
  }
}

.LandingSectionWhatWeDoCardWrapper {
  width: 100%;
  margin-bottom: 2rem;

  @include for-tablet-landscape-up {
    width: calc(50% - 2rem);
  }

  &:hover {
    .LandingSectionWhatWeDoCardImg {
      filter: brightness(1) contrast(1);
      box-shadow: 0 4px 10px rgba(39, 130, 252, 0.2), 0 2px 2px rgba(39, 130, 252, 0.2);
      box-shadow: 0 0px 0px #0C74FF, 0 10px 40px rgba(12, 116, 255, 0.30);
    }
  }
}

.LandingSectionWhatWeDoCardImg {
  width: 100%;
  height: 8rem;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(39, 130, 252, 0.2), 0 1px 1px rgba(39, 130, 252, 0.2);
  filter: brightness(0.9) contrast(0.9);
  transition: box-shadow 0.2s, filter 0.2s;
}

.LandingSectionWhatWeDoCardTitle {
  margin-top: 1.5rem;
  color: $text-primary;
  font-size: 2rem;
}

.LandingSectionWhatWeDoCardSubtitle {
  margin-top: 0.5rem;
  color: $text-secondary;
  font-size: 1.25rem;
}

.LandingSectionDigitalLeadManagementCard {
  width: 100%;
  box-sizing: border-box;
  color: $text-primary;
  height: auto;
  margin-top: 2rem;
  margin-left: 0rem;
  padding: 2rem;
  background-color: $color-secondary;
  box-shadow: 0 0px 0px #0C74FF, 0 5px 20px rgba(12, 116, 255, 0.20);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include for-tablet-landscape-up {
    align-items: flex-start;
    margin-left: 2rem;
    margin-top: 0rem;
    width: 25rem;
  }
}

.LandingSectionDigitalLeadManagementCardItem {
  text-align: center;

  @include for-tablet-landscape-up {
    text-align: left;
  }
}

.LandingSectionDigitalLeadManagementCardSignUp {
  margin-top: 1rem;
  width: 10rem;
}

.LandingSectionWhyOpenHomelyInfoCard {
  width: 100%;
  color: $text-primary;
  box-sizing: border-box;
  height: auto;
  margin-top: 2rem;
  margin-left: 0rem;
  padding: 2rem;
  background-color: white;
  box-shadow: 0 0px 0px #0C74FF, 0 5px 20px rgba(12, 116, 255, 0.20);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @include for-tablet-landscape-up {
    align-items: flex-start;
    margin-left: 2rem;
    margin-top: 0rem;
    width: 50rem;
  }
}

.LandingSectionWhyOpenHomelyInfoCardTitle {
  font-size: 1.5rem;
}

.LandingSectionInfoLoginImg {
  margin-top: 2rem;
  box-shadow: 0 0px 0px #0C74FF, 0 10px 40px rgba(12, 116, 255, 0.40);
  border-radius: 1rem;

  width: 100%;
  max-width: 100%;
  height: 100%;

  @include for-tablet-landscape-up {
    margin-top: 0;
    margin-left: 2rem;
    width: 50%;
  }
}

.LandingSectionVirtualSignInImg {
  margin: auto;
  margin-top: 2rem;

  width: 100%;
  max-width: 100%;
  height: 100%;

  @include for-tablet-landscape-up {
    width: 80%;
  }
}

.LandingSectionInfoLoginImg--small {
  width: 15rem;
  max-width: 100%;
}

.LandingSectionInfoBubble {
  padding: 0.5rem;
  margin-top: 2rem;
  margin: auto;
  width: 25rem;
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;

  border-radius: 0.5rem;
}

.LandingSectionInfoBubble--small {
  width: 15rem;
  max-width: 100%;
}

.LandingSectionVirtualInfoContent {
  margin-top: 2rem;
}

.LandingSectionVirtualInfoBubble {
  padding: 0.5rem;
  margin-right: 1rem;
  width: 25rem;
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.LandingSectionVirtualInfoLoginImg {
  box-shadow: 0 2px 5px #2782FC, 0 1px 1px #2782FC;
  border-radius: 1rem;

  width: 25rem;
  max-width: 100%;
  height: 100%;

  @include for-tablet-landscape-up {
    margin-top: 0;
    margin-left: 2rem;
  }
}

.LandingSectionPersonalCommunicationContent {
  justify-content: center;
}

.LandingSectionPersonalCommunicationArrow {
  height:3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  transform: rotate(90deg);

  @include for-tablet-landscape-up {
    margin: 0rem -1rem 0rem 0rem;
    height: 5rem;
    transform: rotate(0deg);
  }
}

.LandingSectionPersonalCommunicationArrow--small {
  height: 2rem;
}


.LandingSectionHowItWorks {
  margin: auto;
  margin-top: 4rem;
  padding: 2rem;
}

.LandingSectionHowItWorksContent {
  margin: auto;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }
}

.LandingSectionHowItWorksItem {
  position: relative;
  margin-top:2rem;
  width: 75%;
  box-sizing: border-box;
  padding: 2rem;
  font-size: 1.25rem;

  @include for-tablet-landscape-up {
    width: 25%;
    box-sizing: unset;
  }

}

.LandingSectionHowItWorksItemNumber {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: $color-primary;
  color: $color-white;
}

.LandingSectionPricing {
  margin:auto;
  margin-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.LandingSectionPricingCard {
  margin: auto;

  // max-width: 80%;
  border-radius: 1rem;
  background-color: $color-white;

  box-shadow: 0 0px 0px #0C74FF, 0 10px 80px rgba(12, 116, 255, 0.60);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include for-tablet-landscape-up {
    max-width: 40%;
  }
}

.LandingSectionPricingCardTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15rem;
  padding: 2.5rem;
  border-radius: 1rem 1rem 0rem 0rem;
  box-sizing: border-box;
  text-align: center;
  background-color: $color-primary;

  @include for-tablet-portrait-up {
    height: 12rem;
  }
}

.LandingSectionPricingCardTitle {
  font-size: 2.5rem;
  color: $color-white;
  margin-bottom: 1rem;
}

.LandingSectionPricingCardSubtitle {
  font-size: 0.7rem;
  color: $color-white;
}

.LandingSectionPricingCardFeatures {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LandingSectionPricingCardFeature {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  color: $text-primary;
}

.LandingSectionPricingCardBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  box-sizing: border-box;
  text-align: center;
}

.LandingSectionPricingStripe {
  position: absolute;
  bottom:20%;
  height: 10rem;
  width: 100%;
  transform: skewY(-10deg);
  transform-origin: 0;
  z-index: -1;
  background: linear-gradient(to right, rgba(39, 130, 252, 0.57), rgba(39, 130, 252, 0.2));
}

.LandingFooterSection {
  position: relative;
  color: $text-primary;
  padding-top: 2rem;
}

.LandingFooter {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 4rem;

  height: 8rem;
  width: 100vw;

  background: linear-gradient(to right, #063A80, #0C74FF 50%, #063A80);
  color: $color-white
}

.LandingFooterBgImg {
   position: absolute;
   top: 0;
   left: 0;
   filter: opacity(0.1);

   width: 100%;
   height: 100%;

   background-image: url("../../assets/housesclouds.jpg");
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;

   @include for-tablet-portrait-up {
     width: 100%;
     height: 100%;
   }
 }

.LandingFooterInner {
  width: 100%;
  max-width: var(--landing-gutter);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 2rem;

  @include for-tablet-portrait-up {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}


.LandingFooterLeft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.LandingFooterRight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.LandingFooterLogo {
  font-size: 1.5rem;
  height: 2rem;
  width: auto;
  transform: translateY(-5px);
}

.LandingFooterEmail {
  font-size: 0.75rem;
}

.LandingFooterLink {
  font-size: 0.75rem;
  margin-left: 1rem;
  cursor: pointer;
}

