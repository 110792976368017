@import "config/common.scss";

.SearchWrapper {
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;

  @include for-tablet-portrait-up {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.SearchLeft {
  position: relative;

  width: 100%;
  @include for-tablet-portrait-up {
    width: auto;
  }
}

.SearchIcon {
  position: absolute;
  top: 50%;
  left: 1rem;

  width: 1rem;
  height: auto;

  transform: translate(-25%, -50%);

  @include for-tablet-portrait-up {
    transform: translate(-25%, -50%);
  }
}

.SearchInput {
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  height: 2rem;

  padding: 1.5rem 1.5rem 1.5rem 2rem;

  border: 0.1rem solid $color-secondary;
  border-radius: 0.5rem;
  outline: none;

  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);

  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);
  }

  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    width: 20rem;
    padding: 1rem 1rem 1rem 2rem;
    font-size: 1rem;
  }

}

.SearchRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  margin-bottom: 1rem;
  @include for-tablet-portrait-up {
    margin-bottom: 0;
  }
}

.SearchSortIcon {
  margin-left: 0.5rem;
}
