@import "config/common.scss";

.Payment {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;

  height: 100vh;
  width: 100vw;

  overflow-y: scroll;
}

.PaymentWrapper {
  position: relative;
  margin: auto;
}

.PaymentInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PaymentLogo {
  margin: auto;
  margin-bottom: 2rem;
  height: 4rem;
  width: auto;

  display: none;

  animation-name: animate-up;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;

  @include for-tablet-portrait-up {
    display: block;
  }
}

.PaymentFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: white;
  border-radius: 1rem;
  padding: 4rem 2rem 2rem 2rem;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
}

.PaymentTitle {
  font-size: 3rem;
  color: $text-primary;
  font-weight: 700;
}

.PaymentHR {
  max-width: 100%;
  width: 25rem;
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.PaymentFormHR {
  margin-bottom: 0.5rem;
  width: 2rem;
}

.PaymentPricing {
  margin-top: 1rem;
  color: $text-secondary;
  font-weight: 500;
  font-size: 0.75rem;
}

.PaymentCTA {
  margin-top: 3rem;
  color: $text-primary;
  font-weight: 700;
  font-size: 1rem;
}

.PaymentButton {
  margin-top: 2rem;
  max-width: 8rem;
}

.PaymentForm {
  margin-top: 3rem;
  width: 25rem;
  max-width: 100%;
}

.PaymentFormGroup {
  padding: 0;
  border-style: none;
  // background-color: $color-primary;
  will-change: opacity, transform;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.PaymentFormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  box-sizing: border-box;
  padding: 11px 15px 11px 15px;
  background-color: $color-primary;
  border-radius: 4px;
}

.PaymentLogout {
  color: $text-secondary;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.PaymentFormInputTitle {
  color: $text-primary;
  font-size: 1.6rem;
  margin-bottom: 0.25rem;
  text-align: left;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.PaymentFormInput {
  margin-bottom: 1rem;
  max-width: 25rem;
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.5rem;
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.PaymentUnsubscribedReasonWrapper {
  max-width: 25rem;
}

.PaymentUnsubscribedReason {
  margin-top: 1rem;
  width: 100%;
  color: $color-red;
  text-align: center;
}

.PaymentFormInputFeedback {
  margin-bottom: 2rem;
  width: 100%;
  //height: 1rem;
  color: $color-red;
  text-align: left;
}

.PaymentFormSpinner {
  margin-top: 2rem;
}
