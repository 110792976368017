@import "config/common.scss";

.HR {
  margin: auto;
  margin-left: 0rem;
  margin-top: 0.2rem;
  width: 6rem;
  height: 0.2rem;
  color: $color-primary;
  border: none;
  background-color: $color-primary;
  border-radius: 1rem;
}
