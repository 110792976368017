@import "config/common.scss";

.HomeCard {
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  background-color: $color-white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
}

.HomeCardTop {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 1rem;

  @include for-tablet-portrait-up {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.HomeCardTopLeft {
  display: flex;
  flex-direction: row;
}

.HomeCardTopImage {
  margin-right: 1rem;
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: center;

  display: none;

  @include for-tablet-portrait-up {
    display: block;
  }
}

.HomeCardContent {
  display: flex;
  flex-direction: column;
}

.HomeCardTopRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;

  @include for-tablet-portrait-up {
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: auto;
    margin-bottom: 0rem;
  }
}

.HomeCardTopRightChips {
  margin-bottom: 0;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @include for-tablet-portrait-up {
    margin-bottom: 0.5rem;
    margin-right: 0rem;
  }
}

.HomeCardTopRightChip {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: $color-white;
  background-color: $color-primary;
}

.HomeCardTopRightChip--icon {
  margin-left: 0.25rem;
}

.HomeCardTopRightChip--warn {
  color: $color-white;
  background-color: $color-red;
}

.HomeCardTopRightDropdown {
  position: relative;
  width: 15rem;
  max-width: 100%;
}

.HomeCardTopRightDropdownTitle {
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.HomeCardTopRightDropdownOptions {
  position: absolute;
  top:0;
  right:0;
  z-index:1;

  margin-top: 1.25rem;

  background-color: white;
  padding: 1rem 1rem 0rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);

  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.HomeCardTopRightDropdownOption {
  margin-bottom: 1rem;
  cursor: pointer;
}

.HomeCardTopRightButtons {
  display: flex;
  flex-direction: row;
}

.HomeCardTitle {
  font-size: 1.5rem;
}

.HomeCardHR {
  margin-bottom: 0.5rem;
}

.HomeCardSubtitle {
  color: $text-secondary;
}

.HomeCardLeadList {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.HomeCardLeadCard {
  width: 100%;
  min-height: 4rem;
  max-width: 100%;

  padding: 0.5rem 0rem 0rem 0rem;

  border-left: 0rem solid $color-white;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;

  border-radius: 0.5rem;
  background-color: $color-white;

  text-align: left;

  box-sizing: border-box;
}

.HomeCardLeadCard--hoverable {
  transition: border-left 0.2s, padding 0.2s;
  &:hover {
    border-left: none;
    padding: 0;

    @include for-tablet-portrait-up {
      border-left: 0.5rem solid $color-primary;
      padding: 0.5rem 0rem 0rem 1rem;
    }
  }
}

.HomeCardLeadCardLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.HomeCardLeadCardInfo--name {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: $text-secondary;
}

.HomeCardLeadCardInfo--nameBold {
  color: $text-primary;
  font-size: 1.25rem;
}

.HomeCardLeadCardInfo--email {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: $text-secondary;
}

.HomeCardLeadCardInfo--phone {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: $text-secondary;
}

.HomeCardLeadCardInfo--leadsList {
  display: none;
  margin-right: 0.5rem;
  word-break: break-all;
  @include for-tablet-portrait-up {
    display: block;
  }
}

.HomeCardLeadCardInfo--notes {
  margin-top: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 12rem;
  border-radius: 0.25rem;
  color: $text-secondary;
  border: 0.1rem solid $color-secondary;
  font-family: inherit !important;
  font-size: 0.75rem !important;
}

.HomeCardLeadCardRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.HomeCardLeadCardBottom {
  margin: 1rem 0rem 1rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: flex-start;
}

.HomeCardLeadButton {
  width: 8rem;
  height: 0.5rem;
  font-size: 0.75rem;
  margin-left: 0rem;
}

.HomeCardLeadDeleteButton {
  color: $text-secondary;
  cursor: pointer;
}

.HomeCardLeadDeleteButton--leadsList {
  display: none;
  @include for-tablet-portrait-up {
    display: block;
  }
}

.HomeCardLeadButton--below {
  margin-left: 0;
  margin-top: 0.5rem;
}

.HomeCardLeadsExportOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $text-primary;
  cursor: pointer;
}

.HomeCardLeadsExportOption {
  display: flex;
  align-items: center;
  text-align: center;
}

.LeadExportOptionIcon {
  margin-left: 0.5rem;
  display: none;
  @include for-tablet-landscape-up {
    display: block;
  }
}

.HomeCardFollowUpAll {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
}

.HomeCardLeadExportOptionIcon {
  margin-left: 1rem;
}

.HomeCardNoLeadsMessage {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.HomeCardNoLeadsLink {
  font-weight: 700;
  color: $color-primary;
}

.HomeCardBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.HomeCardBottom--column {
  flex-direction: column;
}

.HomeCardBottomRight {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;

  @include for-tablet-portrait-up {
    text-align: initial;
    flex-direction: row;
    justify-content: center;
    align-items: initial;
  }
}

.HomeCardBottomRightLink {
  left: 0;
  cursor: pointer;
}

.HomeCardBottomRightCopyIcon {
  margin-right: 0.5rem;
}

.HomeCardBottomLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include for-tablet-portrait-up {
    flex-direction: row;
    justify-content: initial;
  }
}

.HomeCardBottomLeftStart {
  margin-bottom: 1rem;
  margin-right: 0.75rem;
  color: $color-primary;
  font-weight: 500;
  transition: font-weight 0.2s;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }

  @include for-tablet-portrait-up {
    margin-bottom: 0rem;
  }
}

.HomeCardBottomLeftLeads {
  color: $text-primary;
  font-weight: 500;
  transition: font-weight 0.2s;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
}

.HomeCardIcon {
  margin-left: 1rem;
  cursor: pointer;
}
