@import "config/common.scss";

.ModalContainer {
  position: fixed;
  top: 0;
  bottom:0;

  width: 100vw;

  display: flex;
  justify-content: center;

  overflow-y: scroll;
  overflow-x: hidden;

  background-color: $modal-bg;
  z-index: 999;
};

.Modal {
  position: relative;

  max-width: calc(100% - 4rem);

  margin: auto;
  padding: 2rem;

  background-color: $color-white;
  border-radius: 2rem;
  z-index: 999;
}

.ModalClose {
  margin-bottom: 1rem;
  font-weight: 700;
  cursor: pointer;
}
