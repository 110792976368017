@import "config/common.scss";

.Dashboard {
    min-height: calc(100vh - 11rem);

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    color: $text-primary;
}

.DashboardTop {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.DashboardTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DashboardTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
}

.DashboardCard {
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
  background: $color-white;
  color: $text-primary;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.5rem;
}

.DashboardCardTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DashboardCardTitle--description {
  font-size: 0.75rem;
  font-weight: 500;
  color: $text-secondary;
  display: none;

  @include for-tablet-portrait-up {
    display: block;
  }
}

.DashboardCardSubtitle {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: $text-primary;
}

.DashboardCardButton {
  margin-top: 1rem;
  width: 10rem;
}


