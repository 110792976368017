@import "config/common.scss";

.DialogContainer {
  color: $text-primary;
}

.Dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.DialogTitle {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.DialogSubtitle {
  margin-bottom: 2rem;
  font-size: 1rem;
}

.DialogButton {

}
