@import "config/common.scss";

.ProfileSettingsWrapper {
  width: 100%;
}

.ProfileSettingsForm {
  text-align: left;
  width: 100%;
}

.ProfileSettingsFormTitle {
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  font-weight: 500;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.ProfileSettingsFormUpdating {
  position: relative;
}

.ProfileSettingsFormUpdatingLabel {
  position: absolute;
  top: 0;
  font-size: 0.75rem;
  color: $text-secondary;
}

.ProfileSettingsFormTextArea {
  margin-top: 1rem;

  width: 100%;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.ProfileSettingsFormInput {
  width: 100%;
  min-height: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;
  word-break: break-all;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.ProfileSettingsFormInput--disabled {
  background-color: unset;
  padding-left: unset;
  color: $text-secondary;
}

.ProfileSettingsFormImageInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.ProfileSettingsFormInputFeedback {
  margin-bottom: 1rem;
  color: $color-red;
}

.ProfileSettingsFormImageButton {
  width: 12rem;
}

.ProfileSettingsFormImage {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-height: 10rem;
  border-radius: 0.5rem;
}

.ProfileSettingsFormFeedback {
  margin-bottom: 1rem;
}

.ProfileSettingsFormTextAreaHighlight {
  position: relative;
  color: $color-primary;
  font-weight: 700;
  cursor: pointer;
}

.ProfileSettingsMonacoDescription {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.ProfileSettingsMonacoDescriptionItem {
  margin-bottom: 0.5rem;
  color: $text-secondary;
  font-size: 0.75rem;
}

.ProfileSettingsMonacoDescription--keyword {
  color: $color-primary;
}

