@import "config/common.scss";

.LeadFollowUpContainer {
  position: relative;
  width: 100%;
}

.LeadFollowUpCheck {
  margin-left: 0.5rem;
  height: 1rem;
  width: auto;
  transform: translateY(10%);
  font-size: 1rem;
  color: $color-primary;
}
