@import "config/common.scss";

.SMS {

  max-width: 100%;
  width: 50rem;

  display: block;
  white-space: pre-line;
  text-align: left;
  margin: 2rem;
  margin-left: auto;
  margin-right: auto;

  color: $text-primary;

  h3 {
    margin-bottom: 1rem;
  }

  b {
    color: black;
    font-weight: 700;
  }

  div {
    margin-bottom: 1rem;
  }

  ol {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1rem;
  }
}
