@import "config/common.scss";

.HomesTop {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.HomesTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HomesTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
  margin-right: 0.5rem;
}

.HomesList {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  @include for-tablet-portrait-up {
    justify-content: unset;
  }
}

.HomeCardAdd {
  width: 100%;
  height: 6rem;
  max-width: 100%;

  margin-bottom: 1rem;
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.5rem;
  background-color: $color-white;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);

  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 10px 20px rgba(12, 116, 255, 0.23), 0 6px 6px rgba(12, 116, 255, 0.23);
  }

  @include for-tablet-portrait-up {
    width: unset;
    min-width: 12rem;

    margin-right: 1rem;
  }
}

.HomesListEmpty {
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
  background: #FFFFFF;
  color: #0E0E0E;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.5rem;
}

.HomesListEmptyTitle {
  font-size: 1.25rem;
  font-weight: 700;
}

.HomesListEmptySubtitle {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.HomesListEmptyButton {
  width: 10rem;
}
