@import "config/common.scss";

.Leads {
  min-height: calc(100vh - 11rem);
  width: 100%;

  color: $text-primary;
}

.LeadsTop {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.LeadsTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LeadsTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-primary;
  margin-right: 0.5rem;
}

.LeadsSubtitle {
  font-size: 1rem;
  font-weight: 700;
  color: $text-primary;
}

.LeadsList {
  display: flex;
  flex-direction: column;
}

.LeadsListEmpty {
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
  background: #FFFFFF;
  color: #0E0E0E;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.5rem;
}

.LeadsListEmptyTitle {
  font-size: 1.25rem;
  font-weight: 700;
}

.LeadsListEmptySubtitle {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.LeadsListEmptyButton {
  width: 10rem;
}
