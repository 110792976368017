@import "config/common.scss";

.OpenHomePage {
  position: relative;

  margin: auto;

  height: 100vh;
  width: 100vw;

  overflow-x: hidden;
  overflow-y: scroll;
}

.OpenHomePageWrapper {
  position: relative;
  margin: auto;
}

.OpenHomePageInner{
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  width: 100%;

  background-color: $color-white;

  position:absolute;
  top:0;
  left:0;
  width:100vw;
  max-width: 100vw;
  height:100vh;
  z-index: 1;

  overflow-x: hidden;
  overflow-y: scroll;

  @include for-tablet-landscape-up {
    padding: 0rem 2rem 0rem 2rem;
  }
}

.OpenHouseQuit {
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;

  z-index: 1;

  transition: font-weight 0.2s;
  &:hover {
    font-weight: 900;
  }

  display: none;

  @include for-tablet-landscape-up {
    display: block;
  }
}

.OpenHouseQuitIcon {
  margin-right: 0.5rem;
}

@keyframes BGBarAnimation {
  from {opacity: 0;}
  to {opacity: 1;}
}

.OpenHomeBGBar {
  position: absolute;
  top: 50%;
  left: -25%;
  height: 10rem;
  width: 150vw;
  background-color: rgba(12, 116, 255, 0.5);
  transform: rotate(12deg);
  z-index: -1;
  animation: BGBarAnimation 1s ease-in-out 0s 1 forwards;
}

.OpenHomeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;

  @include for-tablet-landscape-up {
    padding: 2rem;
  }
}

.OpenHomeInner {
  position: relative;
  margin: auto;
}

.OpenHomeRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: auto;
}

.OpenHomeContainer--centered {
  justify-content: center;
}

.OpenHomeImageTitle {
  color: $text-primary;
  font-size: 1.25rem;
}

.OpenHomeImageTitleHR {
  margin-bottom: 0.5rem;
}

.OpenHomeImageAddress {
  color: $text-secondary;
  margin-bottom: 0.5rem;
}

.OpenHomeImageWrapper {
  max-width: 40%;
  display: none;

  @include for-tablet-landscape-up {
    display: block;
  }
}

.OpenHomeImage {
  height: auto;
  max-height: 30rem;
  max-width: 100%;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
}

.OpenHome {
  margin-right: 0rem;
  padding: 1rem;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  background-color: $color-white;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);

  @include for-tablet-landscape-up {
    margin-right: 1rem;
    width: 50%;
  }
}

.OpenHome--fullwidth {
  width: 100%;
}

.OpenHomeTitle {
  font-size: 3rem;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1rem;
}

.OpenHomeSubtitleRow {
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;

  @include for-tablet-landscape-up {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.OpenHomeHR {
  margin-bottom: 2rem;
}

.OpenHomeSubtitle {
  font-size: 1.5rem;
  font-weight: 700;
}

.OpenHomeAddress {
  font-size: 1.5rem;
  margin-bottom: 2rem;

  @include for-tablet-landscape-up {
    margin-bottom: 0;
    color: $text-primary;
    font-weight: 500;
  }
}

.OpenHomeForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.OpenHomeFormInputTitle {
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  font-weight: 500;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.OpenHomeFormInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.OpenHomeFormInputTag {
  margin-right: 0.5rem;
  color: $text-primary;
}

.OpenHomeFormInput {
  width: 100%;
  height: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
  border: none;
  border-radius: 0.5rem;
  background-color: $color-secondary;
  font-size: 1.6rem;

  @include for-tablet-portrait-up {
    font-size: 1rem;
  }
}

.OpenHomeFormCheckboxInput {
  margin-right: 0.5rem;
  border: 0.1rem solid $color-primary;
  background-color: $color-secondary;

  min-width: 1rem;

  transition: background-color 0.2s;
  &:checked {
    background-color: $color-primary;
  }
}

.OpenHomeAgreementLink {
  color: $color-primary;
  cursor: pointer;
}

.OpenHomeFormInputFeedback {
  margin-bottom: 1rem;
  color: $color-red;
}

.OpenHomeCheckboxWrapper {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OpenHomeCheckboxDescription {
  font-size: 1rem;
  font-weight: 300;
}

.OpenHomeFormButton {
  margin: auto;
}

.OpenHomeSuccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-color: $color-white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
}

.OpenHomeSuccessIcon {
  height: 12rem;
  width: auto;
  font-size: 4rem;
  color: $color-primary;
}

.OpenHomeSuccessText {
  font-size: 2rem;
  font-weight: 700;
  color: $text-primary;
}

.OpenHomeError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-color: $color-white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(12, 116, 255, 0.23), 0 1px 2px rgba(12, 116, 255, 0.23);
}

.OpenHomeErrorIcon {
  height: 8rem;
  width: auto;
  font-size: 4rem;
  color: $color-primary;
}

.OpenHomeErrorText {
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: $text-primary;
}

.OpenHomeErrorTextSecondary {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: $text-secondary;
}

.OpenHomeErrorButton {
  margin-top: 1rem;
}
